.All {
  width:100%;
  display:grid;
  background-color: #eaeaea;
}
.ContentArea {
  background-image: url(https://res.cloudinary.com/du2jqqdk2/image/upload/v1713465162/endlessness_zp9v3z.jpg);
  background-color: #342831;
  background-size:auto;
  background-position: top;
  background-attachment:scroll;
  background-repeat: repeat-y;
  min-height: 100vh;
}


@media screen and (min-width: 1024px) {
  .All {
    display: grid;
    grid-template-columns: 10fr 20fr 10fr;
  }
}

.CardCollection {
  margin:auto;
  display: flex;
  justify-content:space-around;
  padding: 2%;
  flex-flow: row wrap;
  overflow:hidden;
}

.Postform {
  margin: 25px;
  padding:25px;
  background-color:aliceblue;
  font-size: calc(10px + 1vmin);
  line-height: calc(10px + 3vmin);
}

.Datenschutz {
  margin: 25px;
  padding:25px;
  background-color:aliceblue;
  font-size: calc(10px + 1vmin);
  line-height: calc(10px + 3vmin);
}

.ListOfItems {
    background-color: transparent;
    position: relative;
    display: block;
    margin-right: 5px;
    list-style-type:circle;
}
.BulletPoints {
  display: list-item;
}
#myCanvas {
  border: 3px dotted #000;
}